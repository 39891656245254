import { navigate } from 'gatsby';
import { useTranslation } from 'react-i18next';
import React, { memo, useContext, useEffect, useState } from 'react';
import { FcGoogle } from 'react-icons/fc';
import { GrFacebookOption } from 'react-icons/gr';
import { MdEmail } from 'react-icons/md';
import { GiSpy } from 'react-icons/gi';
// import { BsForwardFill } from 'react-icons/bs';
import { HiOutlineLogin } from 'react-icons/hi';
import { GoSignIn } from 'react-icons/go';
import * as Yup from 'yup';
import { Formik, Form } from 'formik';
import { getFieldProps } from '../utils';
import BaseModal from './BaseModal';
import Button from '../components/shared/Button';
import ModalContext from '../contexts/ModalContext';
import UserContext from '../contexts/UserContext';
import Input from '../components/shared/Input';

const AuthModal = () => {
  const { t } = useTranslation();
  const [open, setOpen] = useState(false);
  const [isLoadingGoogle, setLoadingGoogle] = useState(false);
  const [isLoadingAnonymous, setLoadingAnonymous] = useState(false);
  const [isLoadingFacebook, setLoadingFacebook] = useState(false);
  const [isLoadingEmail, setLoadingEmail] = useState(false);
  const [emailLogIn, setEmailLogIn] = useState(true);

  const { emitter, events } = useContext(ModalContext);
  const {
    user,
    loginWithGoogle,
    loginAnonymously,
    loginWithEmail,
    loginWithFacebook,
    isEmail,
    setIsEmail,
    setLoginCredential,
    loginCredential,
    registerWithEmail,
    sendPasswordResetEmail,
  } = useContext(UserContext);

  useEffect(() => {
    const unbind = emitter.on(events.AUTH_MODAL, () => setOpen(true));
    return () => unbind();
  }, [emitter, events]);

  const handleSignInWithGoogle = async () => {
    setLoadingGoogle(true);
    await loginWithGoogle();
    setLoadingGoogle(false);
  };

  const handleSignInWithFacebook = async () => {
    setLoadingFacebook(true);
    await loginWithFacebook();
    setLoadingFacebook(false);
  };

  const handleSignInAnonymously = async () => {
    setLoadingAnonymous(true);
    await loginAnonymously();
    setLoadingAnonymous(false);
  };

  const handleSignInWithEmail = async () => {
    setLoadingEmail(true);
    await loginWithEmail();
    setLoadingEmail(false);
  };

  const handleSignUpWithEmail = async () => {
    setLoadingEmail(true);
    await registerWithEmail();
    setLoadingEmail(false);
  };

  const handleGotoApp = () => {
    navigate('/app/dashboard');
    setOpen(false);
  };

  const getTitle = () =>
    user
      ? t('modals.auth.welcome', { name: user.displayName || 'Jane Doe' })
      : t('modals.auth.whoAreYou');

  const getMessage = () =>
    user ? t('modals.auth.loggedInText') : t('modals.auth.loggedOutText');

  const getEmailTitle = () =>
    t(
      emailLogIn
        ? 'modals.auth.signInWithEmail'
        : 'modals.auth.signUpWithEmail',
    );

  // const loggedInAction = () => {
  //   handleGotoApp();
  //   return (
  //     <>
  //       <Button
  //         outline
  //         className="mb-3"
  //         onClick={logout}
  //         icon={HiOutlineLogout}
  //       >
  //         {t('shared.buttons.logout')}
  //       </Button>
  //       <Button onClick={handleGotoApp} icon={BsForwardFill}>
  //         {t('landing.hero.goToApp')}
  //       </Button>
  //     </>
  //   );
  // };

  const emailForm = () => {
    const schema = Yup.object().shape({
      email: Yup.string()
        .email()
        .required(t('shared.forms.validation.required')),
      name: Yup.string().max(255, t('shared.forms.validation.max')),
      password: Yup.string()
        .required(t('shared.forms.validation.required'))
        .min(6, t('shared.forms.validation.min', { number: 6 })),
    });
    return (
      <>
        <Formik
          validateOnBlur
          initialValues={loginCredential}
          validationSchema={schema}
          onSubmit={(values) => {
            setLoginCredential(values);
          }}
        >
          {(formik) => (
            <Form>
              {!emailLogIn && (
                <Input
                  label={t('shared.forms.name')}
                  className="mb-6"
                  placeholder="Jane"
                  {...getFieldProps(formik, schema, 'name')}
                />
              )}
              <Input
                label={t('shared.forms.email')}
                className="mb-6"
                placeholder="janesmith@example.com"
                {...getFieldProps(formik, schema, 'email')}
              />
              <Input
                label={t('shared.forms.password')}
                className="mb-8"
                type="password"
                placeholder="********"
                {...getFieldProps(formik, schema, 'password')}
              />
              <Button
                isLoading={isLoadingEmail}
                icon={emailLogIn ? HiOutlineLogin : GoSignIn}
                className="w-full"
              >
                {emailLogIn ? 'Sign In' : 'Sign Up'}
              </Button>
            </Form>
          )}
        </Formik>
        {emailLogIn && (
          <p className="mt-2">
            <button
              className="text-primary-custom"
              onClick={() => sendPasswordResetEmail()}
            >
              forget password ?
            </button>
          </p>
        )}
        <p className="mt-2">
          {emailLogIn ? 'Do not have account' : 'Already have an account?'}
          <button
            className="text-primary-custom ml-2"
            onClick={() => setEmailLogIn(!emailLogIn)}
          >
            {!emailLogIn ? 'Sign In' : 'Sign Up'}
          </button>
        </p>
      </>
    );
  };

  const loggedOutAction = (
    <div className="flex flex-col">
      {isEmail ? (
        emailForm()
      ) : (
        <>
          <Button
            isLoading={isLoadingGoogle}
            onClick={handleSignInWithGoogle}
            icon={FcGoogle}
          >
            {t('modals.auth.buttons.google')}
          </Button>
          <Button
            className="mt-3"
            isLoading={isLoadingFacebook}
            onClick={handleSignInWithFacebook}
            icon={GrFacebookOption}
          >
            {t('modals.auth.buttons.facebook')}
          </Button>
          <Button
            className="mt-3"
            // isLoading={isLoadingEmail}
            onClick={() => setIsEmail(true)}
            icon={MdEmail}
          >
            {t('modals.auth.buttons.email')}
          </Button>
          <Button
            className="mt-3"
            isLoading={isLoadingAnonymous}
            onClick={handleSignInAnonymously}
            icon={GiSpy}
          >
            {t('modals.auth.buttons.anonymous')}
          </Button>
        </>
      )}
    </div>
  );

  useEffect(async () => {
    if (loginCredential.email) {
      if (emailLogIn) {
        await handleSignInWithEmail();
      } else {
        await handleSignUpWithEmail();
      }
    }
  }, [loginCredential]);

  useEffect(() => {
    if (user) {
      handleGotoApp();
    }
  }, [user]);

  return (
    <BaseModal
      title={isEmail ? getEmailTitle() : getTitle()}
      state={[open, setOpen]}
      action={!user && loggedOutAction}
    >
      {!isEmail && <p className="leading-loose">{getMessage()}</p>}
    </BaseModal>
  );
};

export default memo(AuthModal);
